import { createStore } from 'vuex'
import { authApi } from "@/api";

export default createStore({
  state: {
    config: {},
  },
  getters: {
    config: (state) => state.config,
  },
  mutations: {
    setConfig(state, data) {
      state.config = data;
    },
  },
  actions: {
    setConfig({ commit }, data) {
      authApi.getConfig().then(res => {
        commit("setConfig", res.data);
      })

    },
  },
  modules: {}
})
